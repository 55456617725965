/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstname
      lastname
      department
      designation
      verified
      companyId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstname
        lastname
        department
        designation
        verified
        companyId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getValuation = /* GraphQL */ `
  query GetValuation($id: ID!) {
    getValuation(id: $id) {
      id
      title
      address
      type
      rooms
      baths
      area
      user {
        id
        firstname
        lastname
        department
        designation
        verified
        companyId
        createdAt
        updatedAt
        __typename
      }
      userId
      purpose
      level
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listValuations = /* GraphQL */ `
  query ListValuations(
    $filter: ModelValuationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listValuations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        address
        type
        rooms
        baths
        area
        user {
          id
          firstname
          lastname
          department
          designation
          verified
          companyId
          createdAt
          updatedAt
          __typename
        }
        userId
        purpose
        level
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      companyName
      address
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyName
        address
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSprint = /* GraphQL */ `
  query GetSprint($id: ID!) {
    getSprint(id: $id) {
      id
      title
      description
      startAt
      endAt
      isCurrent
      archived
      createdBy {
        id
        firstname
        lastname
        department
        designation
        verified
        companyId
        createdAt
        updatedAt
        __typename
      }
      tickets {
        items {
          id
          sprintId
          ticketId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSprints = /* GraphQL */ `
  query ListSprints(
    $filter: ModelSprintFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSprints(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        startAt
        endAt
        isCurrent
        archived
        createdBy {
          id
          firstname
          lastname
          department
          designation
          verified
          companyId
          createdAt
          updatedAt
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTicket = /* GraphQL */ `
  query GetTicket($id: ID!) {
    getTicket(id: $id) {
      id
      title
      description
      points
      priority
      type
      status
      reportBy {
        id
        firstname
        lastname
        department
        designation
        verified
        companyId
        createdAt
        updatedAt
        __typename
      }
      assignee {
        id
        firstname
        lastname
        department
        designation
        verified
        companyId
        createdAt
        updatedAt
        __typename
      }
      sprints {
        items {
          id
          sprintId
          ticketId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTickets = /* GraphQL */ `
  query ListTickets(
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        points
        priority
        type
        status
        reportBy {
          id
          firstname
          lastname
          department
          designation
          verified
          companyId
          createdAt
          updatedAt
          __typename
        }
        assignee {
          id
          firstname
          lastname
          department
          designation
          verified
          companyId
          createdAt
          updatedAt
          __typename
        }
        sprints {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
