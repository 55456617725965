<template>
  <div>


      <valuations-navbar></valuations-navbar>
      <div @click="toggleSidebar">
        <fade-transition :duration="200" class="main" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>

  </div>
</template>
<script>
  import ValuationsNavbar from './ValuationsNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';

  export default {
    components: {
      ValuationsNavbar,
      ContentFooter,
      FadeTransition
    },
    data() {
      return {
        sidebarBackground: 'vue' //vue|blue|orange|green|red|primary
      };
    },
    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      }
    },
    computed: {
      userIsAdmin() {
        return this.$store.getters.isLoginUserAdmin;
      }
    },
  };
</script>
<style lang="scss">
.main {
  min-height: 700px;
}
</style>
