<template>
  <footer class="footer px-4">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6">
        <ul class="copyright nav nav-footer justify-content-center justify-content-lg-end">
          <li class="nav-item">
            © {{year}} <a href="//www.realestatedataservices.com" class="font-weight-bold ml-1" target="_blank">realestatedataservices.com</a>
            
          </li>
        </ul>
      </div>

    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
