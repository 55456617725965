/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      firstname
      lastname
      department
      designation
      verified
      companyId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      firstname
      lastname
      department
      designation
      verified
      companyId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      firstname
      lastname
      department
      designation
      verified
      companyId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createValuation = /* GraphQL */ `
  mutation CreateValuation(
    $input: CreateValuationInput!
    $condition: ModelValuationConditionInput
  ) {
    createValuation(input: $input, condition: $condition) {
      id
      title
      address
      type
      rooms
      baths
      area
      user {
        id
        firstname
        lastname
        department
        designation
        verified
        companyId
        createdAt
        updatedAt
        __typename
      }
      userId
      purpose
      level
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateValuation = /* GraphQL */ `
  mutation UpdateValuation(
    $input: UpdateValuationInput!
    $condition: ModelValuationConditionInput
  ) {
    updateValuation(input: $input, condition: $condition) {
      id
      title
      address
      type
      rooms
      baths
      area
      user {
        id
        firstname
        lastname
        department
        designation
        verified
        companyId
        createdAt
        updatedAt
        __typename
      }
      userId
      purpose
      level
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteValuation = /* GraphQL */ `
  mutation DeleteValuation(
    $input: DeleteValuationInput!
    $condition: ModelValuationConditionInput
  ) {
    deleteValuation(input: $input, condition: $condition) {
      id
      title
      address
      type
      rooms
      baths
      area
      user {
        id
        firstname
        lastname
        department
        designation
        verified
        companyId
        createdAt
        updatedAt
        __typename
      }
      userId
      purpose
      level
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      companyName
      address
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      companyName
      address
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      companyName
      address
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSprint = /* GraphQL */ `
  mutation CreateSprint(
    $input: CreateSprintInput!
    $condition: ModelSprintConditionInput
  ) {
    createSprint(input: $input, condition: $condition) {
      id
      title
      description
      startAt
      endAt
      isCurrent
      archived
      createdBy {
        id
        firstname
        lastname
        department
        designation
        verified
        companyId
        createdAt
        updatedAt
        __typename
      }
      tickets {
        items {
          id
          sprintId
          ticketId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSprint = /* GraphQL */ `
  mutation UpdateSprint(
    $input: UpdateSprintInput!
    $condition: ModelSprintConditionInput
  ) {
    updateSprint(input: $input, condition: $condition) {
      id
      title
      description
      startAt
      endAt
      isCurrent
      archived
      createdBy {
        id
        firstname
        lastname
        department
        designation
        verified
        companyId
        createdAt
        updatedAt
        __typename
      }
      tickets {
        items {
          id
          sprintId
          ticketId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSprint = /* GraphQL */ `
  mutation DeleteSprint(
    $input: DeleteSprintInput!
    $condition: ModelSprintConditionInput
  ) {
    deleteSprint(input: $input, condition: $condition) {
      id
      title
      description
      startAt
      endAt
      isCurrent
      archived
      createdBy {
        id
        firstname
        lastname
        department
        designation
        verified
        companyId
        createdAt
        updatedAt
        __typename
      }
      tickets {
        items {
          id
          sprintId
          ticketId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTicket = /* GraphQL */ `
  mutation CreateTicket(
    $input: CreateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    createTicket(input: $input, condition: $condition) {
      id
      title
      description
      points
      priority
      type
      status
      reportBy {
        id
        firstname
        lastname
        department
        designation
        verified
        companyId
        createdAt
        updatedAt
        __typename
      }
      assignee {
        id
        firstname
        lastname
        department
        designation
        verified
        companyId
        createdAt
        updatedAt
        __typename
      }
      sprints {
        items {
          id
          sprintId
          ticketId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTicket = /* GraphQL */ `
  mutation UpdateTicket(
    $input: UpdateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    updateTicket(input: $input, condition: $condition) {
      id
      title
      description
      points
      priority
      type
      status
      reportBy {
        id
        firstname
        lastname
        department
        designation
        verified
        companyId
        createdAt
        updatedAt
        __typename
      }
      assignee {
        id
        firstname
        lastname
        department
        designation
        verified
        companyId
        createdAt
        updatedAt
        __typename
      }
      sprints {
        items {
          id
          sprintId
          ticketId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTicket = /* GraphQL */ `
  mutation DeleteTicket(
    $input: DeleteTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    deleteTicket(input: $input, condition: $condition) {
      id
      title
      description
      points
      priority
      type
      status
      reportBy {
        id
        firstname
        lastname
        department
        designation
        verified
        companyId
        createdAt
        updatedAt
        __typename
      }
      assignee {
        id
        firstname
        lastname
        department
        designation
        verified
        companyId
        createdAt
        updatedAt
        __typename
      }
      sprints {
        items {
          id
          sprintId
          ticketId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSprintTickets = /* GraphQL */ `
  mutation CreateSprintTickets(
    $input: CreateSprintTicketsInput!
    $condition: ModelSprintTicketsConditionInput
  ) {
    createSprintTickets(input: $input, condition: $condition) {
      id
      sprintId
      ticketId
      ticket {
        id
        title
        description
        points
        priority
        type
        status
        reportBy {
          id
          firstname
          lastname
          department
          designation
          verified
          companyId
          createdAt
          updatedAt
          __typename
        }
        assignee {
          id
          firstname
          lastname
          department
          designation
          verified
          companyId
          createdAt
          updatedAt
          __typename
        }
        sprints {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sprint {
        id
        title
        description
        startAt
        endAt
        isCurrent
        archived
        createdBy {
          id
          firstname
          lastname
          department
          designation
          verified
          companyId
          createdAt
          updatedAt
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSprintTickets = /* GraphQL */ `
  mutation UpdateSprintTickets(
    $input: UpdateSprintTicketsInput!
    $condition: ModelSprintTicketsConditionInput
  ) {
    updateSprintTickets(input: $input, condition: $condition) {
      id
      sprintId
      ticketId
      ticket {
        id
        title
        description
        points
        priority
        type
        status
        reportBy {
          id
          firstname
          lastname
          department
          designation
          verified
          companyId
          createdAt
          updatedAt
          __typename
        }
        assignee {
          id
          firstname
          lastname
          department
          designation
          verified
          companyId
          createdAt
          updatedAt
          __typename
        }
        sprints {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sprint {
        id
        title
        description
        startAt
        endAt
        isCurrent
        archived
        createdBy {
          id
          firstname
          lastname
          department
          designation
          verified
          companyId
          createdAt
          updatedAt
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSprintTickets = /* GraphQL */ `
  mutation DeleteSprintTickets(
    $input: DeleteSprintTicketsInput!
    $condition: ModelSprintTicketsConditionInput
  ) {
    deleteSprintTickets(input: $input, condition: $condition) {
      id
      sprintId
      ticketId
      ticket {
        id
        title
        description
        points
        priority
        type
        status
        reportBy {
          id
          firstname
          lastname
          department
          designation
          verified
          companyId
          createdAt
          updatedAt
          __typename
        }
        assignee {
          id
          firstname
          lastname
          department
          designation
          verified
          companyId
          createdAt
          updatedAt
          __typename
        }
        sprints {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sprint {
        id
        title
        description
        startAt
        endAt
        isCurrent
        archived
        createdBy {
          id
          firstname
          lastname
          department
          designation
          verified
          companyId
          createdAt
          updatedAt
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
