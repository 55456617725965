<template>
    <div>
        <div class="row">
          <div class="col-sm-6">
            <a href="/">
            <img width="400px" height="75px" alt="Realestate Data Services - Dubai" src="img/logo/logo.svg">
          </a>
          </div>
          <div class="col-sm-6">
           
            
        <base-dropdown class="nav-link pr-0 float-right">
          <div class="media align-items-center usericon" slot="title">

            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm  font-weight-bold">
                {{ loginUser.name }}
              </span>
                <img height="75px" alt="Realestate Data Services - Dubai" :src="getCompanyLogo(loginUser.companyId)">
            </div>
          </div>

          <template>
            <div class=" dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome!</h6>
            </div>
            <router-link to="/profile" class="dropdown-item">
              <i class="fa fa-user text-primary"></i>
              <span>My profile</span>
            </router-link>
            <div class="dropdown-divider"></div>
            <router-link to="/logout" class="dropdown-item">
              <i class="fa fa-sign-out-alt text-red"></i>
              <span>Logout</span>
            </router-link>
          </template>
        </base-dropdown>



          </div>  
        </div>
    </div>        





</template>

<script>
export default {
  data() {
    return {
      activeNotifications: false,
      showMenu: true,
    };
  },
  computed: {
    loginUser: function() {
      return this.$store.getters.loginUser;
    },
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    getCompanyLogo(companyId) {
      return `https://sprintdashboard-storage-9898ef7f184842-app.s3.me-south-1.amazonaws.com/a/${companyId}.png`;
    }
  },
};
</script>

<style scoped>
  .usericon:hover {
    cursor: pointer;
  }
</style>